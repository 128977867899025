<template>
  <div class="sideScrollWrapper">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

/**
 * A simple component to wrap side swiping using only overflow
 * For image swiper, use SimpleSwiper
 *
 * The content have to expand horizontaly by using for example fixed width or white-space:no-wrap
 * or it will try to fit
 */
export default defineComponent({
  name: 'SideScroll',
});
</script>

<style lang="postcss">
.sideScrollWrapper {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  appearance: none;
  @media (hover: none) {
    scrollbar-width: none; /* firefox */
    &::-webkit-scrollbar {
      width: 0; /* Remove scrollbar space */
      height: 0;
    }
  }
}
.sideScrollRightPad {
  @apply w-24 md:hidden;
  &:after {
    content: '\a0';
  }
}
</style>
